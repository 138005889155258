export default {
  blogerName: 'KUPIDON',
  socialsList: [
    {
      name: 'vk',
      url: 'https://vk.com/kupidon70'
    },
    {
      name: 'telegram',
      url: 'https://t.me/kupidon'
    },
    {
      name: 'youtube',
      url: 'https://youtube.com/@kupidon777?si=JtVIh2nRQK50YOx4'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1go-irrs01.com/c594433de',
      gameTitle: 'The Dog House, Cleocatra, Gates of Olympus',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'monro',
      url: 'https://mnr-irrs01.com/cefd1f062',
      gameTitle: 'Savage Buffalo Spirit Megaways',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'drip',
      url: 'https://drp-irrs01.com/c5ecd531f',
      gameTitle: 'Royal High-Road',
      casinoBonus1: '<strong>150%</strong><div>На первый депозит</div>',
    },
    {
      name: 'starda',
      url: 'https://strd-irrs12.com/c052f4475',
      gameTitle: ' All Lucky Clover 5',
    },
    {
      name: 'legzo',
      url: 'https://gzo-irrs01.com/cbef7a3f3',
      gameTitle: 'Dragon`s Gold 100',
    },
    {
      name: 'volna',
      url: 'https://vln-irrs01.com/c72c3591c',
      gameTitle: 'Dragon`s Gold 100',
    },
    {
      name: 'izzi',
      url: 'https://izzi-irrs01.com/ca5a98f6b',
      gameTitle: 'Dragon`s Gold 100',
    },
    {
      name: 'jet',
      url: 'https://jtfr-itsokarsn.com/cec8fbb8c',
      gameTitle: 'Dragon`s Gold 100',
    },
    {
      name: 'sol',
      url: 'https://sol-diamew02.com/c1ab37fb1',
      gameTitle: 'Doors Of Sol',
    },
    {
      name: 'fresh',
      url: 'https://fresh-sfgjhyrt02.com/c2fd13735',
      gameTitle: 'Doors Of Fresh',
    },
    {
      name: 'rox',
      url: 'https://rox-fwuocypyjf.com/c03d5f27b',
      gameTitle: 'Mechanical Clover',
    }
  ],
  headerText: 'Регистрируйся с промокодом <strong>KUPIDON</strong> и получи 100 фриспинов в следующих играх',
  yourPromocode: 'Твой промокод',
  gameBonus: 'Бонус в игре',
  promocode: 'KUPIDON',
  casinoBonus1: '<strong>100% + 500FS</strong><div>На первый депозит</div>',
  casinoBonus2: '<strong>100FS</strong><div>За регистрацию по промокоду</div>',
  copyMessage: 'Промокод скопирован в буфер обмена',
  theme: 'dark',
};
